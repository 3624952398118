import React, { useEffect, useRef, useState } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

import { useWidth } from '@wap-client/core'

import BracketLeft from '../bracket/bracket-left'
import BracketRight from '../bracket/bracket-right'

import { SpreatProps } from './types'

const Spreat: React.FunctionComponent<SpreatProps> = ({
  align,
  alignItem,
  colors = [],
  duration,
}) => {
  const width = useWidth()
  const [colorArray, setColorArray] = useState(colors)

  const targetRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['-200% center', 'end center'],
  })

  const item = useTransform(scrollYProgress, [1, 0], [1, 0])
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1])
  const xLeftPosAnim = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    [
      width < 1025 ? -150 : -300,
      width < 1025 ? -75 : -150,
      width < 1025 ? -15 : -50,
    ]
  )
  const xRightPosAnim = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    [width < 1025 ? 150 : 300, width < 1025 ? 75 : 150, width < 1025 ? 15 : 50]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setColorArray((prevColors) => {
        const newColors = [...prevColors]
        for (let i = newColors.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[newColors[i], newColors[j]] = [newColors[j], newColors[i]]
        }
        return newColors
      })
    }, duration)

    return () => clearInterval(interval)
  }, [])

  return (
    <section
      className="spreat"
      style={{
        alignItems:
          alignItem === 'start'
            ? 'flex-start'
            : alignItem === 'center'
            ? 'center'
            : alignItem === 'end'
            ? 'flex-end'
            : '',
      }}
      ref={targetRef}
    >
      <motion.div
        style={{
          x: xLeftPosAnim,
        }}
      >
        <BracketLeft colors={colorArray} />
      </motion.div>
      <motion.svg
        width="112"
        height="327"
        viewBox="0 0 112 327"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="road"
      >
        {align === 'right' && (
          <>
            <motion.path
              d="M 14 14 L 14 78.127 C 14 109.088 39.1 134.237 70 134.237 C 85.03 134.237 97.21 146.441 97.21 161.501 C 97.21 176.561 85.03 188.765 70 188.765 C 39.1 188.765 14 213.914 14 244.876 L 14 313"
              stroke="#362E60"
              stroke-width="28"
              stroke-miterlimit="10"
              stroke-linecap="round"
              style={{ pathLength: item }}
            />
            <motion.rect width="33" height="4" fill="white" x="0" y="64" />
            <motion.rect width="4" height="40" fill="white" x="50" y="110" />
            <motion.rect width="33" height="4" fill="white" x="80" y="160" />
            <motion.rect width="4" height="40" fill="white" x="50" y="170" />
            <motion.rect width="33" height="4" fill="white" x="0" y="245" />
          </>
        )}
        {align === 'left' && (
          <>
            <motion.path
              d="M 98 14 L 98 78.127 C 98 109.088 72.9 134.237 42 134.237 C 26.97 134.237 14.79 146.441 14.79 161.501 C 14.79 176.561 26.97 188.765 42 188.765 C 72.9 188.765 98 213.914 98 244.876 L 98 313"
              stroke="#362E60"
              stroke-width="28"
              stroke-miterlimit="10"
              stroke-linecap="round"
              style={{ pathLength: item }}
            />
            <motion.rect width="33" height="4" fill="white" x="80" y="64" />
            <motion.rect width="4" height="40" fill="white" x="50" y="110" />
            <motion.rect width="33" height="4" fill="white" x="80" y="160" />
            <motion.rect width="4" height="40" fill="white" x="50" y="170" />
            <motion.rect width="33" height="4" fill="white" x="80" y="245" />
          </>
        )}
      </motion.svg>
      <motion.div
        style={{
          x: xRightPosAnim,
        }}
      >
        <BracketRight colors={colorArray} />
      </motion.div>
    </section>
  )
}

export default Spreat
